#providerHeader {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0rem 2rem 0rem;
}

#providerHeaderText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#providerScreenBackButton {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: white;
  border: none;
  cursor: pointer;
}

#providerForm {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.providerFormRowContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.providerInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#providerSubmitButtonContainer {
  padding: 2rem 0rem 2rem 0rem;
}

#providerSubmitButton {
  width: 100px;
  height: 40px;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}
