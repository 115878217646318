#loginHeader {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
  }
  
 #loginHeaderText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#backButtonContainer {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: white;
  border: none;
  cursor: pointer;
}

#loginBody {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}
  
#loginForm {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}
  
.loginFormRowContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}
  
.loginInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
  
#loginWithEmailButton {
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7b33e;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
}

#signUpWithEmailButton {
  height: 40px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
}

#emailLoginButton {
  height: 40px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #C04E42;
  border: none;
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 2px 2px 2px 2px #DDDDDD;
}

#googleLoginButton {
  height: 40px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border: none;
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 2px 2px 2px 2px #DDDDDD;
}

#selectAccountTypeButtonsContainer {
  display: flex;
  max-width: 600px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  gap: 2rem;
}

#patientProviderButton {
  height: 40px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
}