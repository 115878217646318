#outerFooter {
  width: 100vw;
  background-color: #1C558A;
  height: 170px;
  display: flex;
  border-radius: 30px 30px 0 0;
}

#footer {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  color: white;
}

#megaMenu {
  display: flex;
  height: 100%;
  align-items: center;
  width: 30%;
  justify-content: space-between;
  text-align: left;
}

#emailInput {
  width: 540px;
  background-color: transparent;
  border-radius: 5px;
  height: 40px;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
  border: 2px solid #fffefd;
}

#emailInput::placeholder {
  color: white;
}

#enterButton {
  height: 40px;
  width: 90px;
  border-radius: 5px;
  border: none;
}
