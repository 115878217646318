.expandedCourse {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.expandedCourse h2 {
  margin-bottom: 20px;
  font-family: "NexaBold";
  font-size: 22px;
}

.expandedCourse p {
  text-align: left;
  margin-bottom: 20px;
  max-width: 800px;
}

.expandedCourse iframe {
  margin-bottom: 20px;
}

.expandedCourse img{
  max-width:900px;
}

.courseContainer{
  height:880px;
}

.downloadButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.downloadButton:hover {
  background-color: #0056b3;
  color: white;
}