@font-face {
  font-family: "NexaBold";
  src: local("NexaBold"), url("./fonts/NexaBold.woff") format("woff");
}

@font-face {
  font-family: "Mulish";
  src: local("NexaBold"), url("./fonts/Mulish.woff") format("woff");
}

.activity_box {
  height: 100%;
  width: 100%;
  position: relative;
  display: "flex";
  justify-content: "space-around";
}

.container {
  display: flex;
  gap: 2%;
  top: 50%;
  position: absolute;
  /* left: 50%; */
  /* transform: translate(-15%, -50%); */
  justify-content: center;
  /* align-items: center; */
}

#columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(0.5 * 100vh);
  max-height: 400px;
  width: calc(0.3 * 100vw);
  margin-top: -25%;
}

.circRow {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.circle {
  height: 50px;
  width: 50px;
  background-color: silver;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_section {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

tr:hover {
  cursor: pointer;
}

.green_circle {
  height: 25px;
  width: 25px;
  background-color: greenyellow;
  border-radius: 50%;
  display: inline-block;
}

.centerLine {
  top: 55%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  position: absolute;
}

.long_rectangle {
  height: 20px;
  width: 20%;
  background-color: greenyellow;
  border-radius: 20px;
}

.short_rectangle {
  height: 20px;
  width: 10%;
  background-color: greenyellow;
  border-radius: 25px;
}

.rowChart {
  display: flex;
  color: white;
}

#redfill {
  background-color: red;
}

#greenfill {
  background-color: green;
}

#yellowfill {
  background-color: yellow;
}

#bluefill {
  background-color: blue;
}

#whitefill {
  background-color: orange;
}

#blackfill {
  background-color: black;
}

#silverfill {
  background-color: silver;
}

#purplefill {
  background-color: purple;
}

.cellArrow {
  width: 100px;
}

.flashingPicsImage {
  max-height: 80%;
  width: auto;
}

#seqText {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

#redtext {
  color: red;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

#greentext {
  color: greenyellow;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

.wordOptions {
  /* display: inline; */
  margin: 10px;
}

.wordOptions:hover {
  cursor: pointer;
}

#correctText {
  color: greenyellow;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

#incorrectText {
  color: red;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

#whitetext {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

#columnsDotToDot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(0.5 * 100vh);
  width: calc(0.3 * 100vw);
  margin: auto;
}

.whiteTextStyle {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

.whiteStyle {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
}

.matchingVowels {
  background-color: transparent;
  color: yellowgreen;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 3em;
  margin: -7px;
}

.unmatchedLetters {
  font-size: 3em;
  font-family: Arial, sans-serif;
  text-shadow: 4px 6px 10px black;
  font-weight: bold;
}

.activitiesButtons {
  margin-bottom: 100px;
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 120px;
}

.activitySettingsButtonsContainer {
  display: flex;
  position: relative;
  bottom: 115px;
}

.activityActionButtonsContainer {
  display: flex;
  justify-content: end;
  gap: 1rem;
}

.activityActionButton {
  min-width: 85px;
  height: 35px;
  border-radius: 5px;
  border: 50px;
}

.dropDownStyle {
  margin-left: 17.5px;
}

.dropdown-toggle::after {
  display: none !important;
}

.choiceImage {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.namingPics {
  margin: 5px;
  height: 150px;
}

.startText {
  color: greenyellow;
  font-weight: bold;
  font-size: 1.5em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
  display: block;
}

#countLetText {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
  display: block;
}

.namingNum {
  margin: 5px;
  font-size: 24pt;
  color: white;
  /* font-weight: bold; */
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
  width: 30px;
  text-align: center;
}

.greenTextHeader {
  color: greenyellow;
  font-weight: bold;
  font-size: 2em;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
  text-align: center;
}

.whiteTextPara {
  color: white;
  font-weight: bold;
  font-size: 24pt;
  text-shadow: 4px 6px 10px black;
  font-family: Arial, sans-serif;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 20px;
}

.highlightListText {
  color: "greenyellow";
  background-color: "greenyellow";
  font-size: 1.5em;
}

.outerImgDiv {
  /* width: 100px; */
  height: 50%;
  display: flex;
  align-items: flex-end;
  /* transform: scale(0.5); */
}

/* .outerImgDiv img {
  height: auto;
  width: 100px;
} */

.flashImage {
  /* height: auto;
  width: 100px; */
  scale: 0.9 1;
  max-width: 100%;
  max-height: 100%;
}

.white_container {
  background-color: white;
  width: 90%;
  height: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.vertCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.seesaw {
  width: 350px;
  height: 10px;
  background-color: green
}

.halfCircle {
  width: 30px;
  height: 50px;
  border-radius: 40px 40px 0px 0px;
  background-color: gray;
  margin-top: -15px;
  z-index: 1;
}

.linkButton {
  background: none!important;
  border: none;
  padding: 0!important;
  font: 16px;
  line-height: 16px;
  /*input has OS specific font-family*/
  cursor: pointer;
}

.loadingScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  position: relative;
  width: 200px;
}

.slider input[type="range"] {
  width: 100%;
  height: 25px;
  background: transparent;
  appearance: none;
}

.slider .slider-ticks {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
}

.slider input[type="range"] {
  appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.slider input[type="range"]:hover {
  opacity: 1;
}

.slider input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
  transition: background .15s ease-in-out;
}

.slider input[type="range"]::-webkit-slider-thumb:hover {
  background: #f8f8f8;
}

.slider input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
  transition: background .15s ease-in-out;
}

.slider input[type="range"]::-moz-range-thumb:hover {
  background: #f8f8f8;
}

.slider-ticks {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
}