#patientInformationHeader {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
}

#patientInformationForm {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

#patientInformationFormRowContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

#patientScreenBackButton {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100px;
    height: 30px;
    background-color: white;
    border: none;
    cursor: pointer;
}

#patientScreenSubmitButton {
    height: 40px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7b33e;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
}