#helpDrawer {
  background-color: #d0443b;
  border-radius: 0px 0px 0px 25px;
  height: 400px;
  display: flex;
  width: 100%;
  padding-left: 15px;
}

.closedDrawerBottomRight {
  position: absolute;
  top: 514px;
  width: 80px;
  transform: translate(0px);
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.openDrawerbottomRight {
  transform: translate(-720px);
  width: 800px;
  z-index: 2;
}

.openHelpDrawer {
  border: solid 2px #d0443b;
  background-color: white;
  display: flex;
  height: 395px;
  border-radius: 0px 0px 0px 25px;
}
