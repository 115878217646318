


#activityTableWrapper {
  padding-left: 2rem;
  padding-right: 2rem;
}

.yellBG {
  background-color: #e7b33e1f;
}

#TSearchSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1145px;
  align-items: center;
  height: 50px;
}

.activitySectionHeader {
  font-family: "NexaBold";
  font-size: 22px;
}

#tableSearch {
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  width: 215px;
  border: 2px solid #4d6e8d;
}

#tableSearch::placeholder {
  color: black;
}

.yellowButton {
  background-color: #e7b33ebf;
  border-radius: 5px;
  border: none;
  height: 40px;
}

.course-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.course-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 20px;
  height: 700px;
  overflow-y: auto;
  
}

.course-card {
  
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.course-card:hover {
  transform: scale(1.05);
}

.course-summary h3 {
  font-family: "NexaBold"; 
  margin: 10px;
  font-size: 1.2em;
  text-align: center;
}


.course-summary p {
  margin-top: 10px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}


.course-details {
  position: relative;
}