thead {
  font-weight: bold;
  background-color: #e7b33e1f;
  padding-left: 5px;
}

th {
  font-style: italic;
  font-weight: 400;
  text-align: left;
  padding-left: 10px;
}

td {
  padding-left: 20px;
}

table {
  border: solid;
  border-color: #e7b33e;
  border-radius: 0px 0px 25px 025px;
  border-collapse: collapse;
  margin: 5px;
  width: 220px;
  height: 700px;
}

#activityTableWrapper {
  padding-left: 2rem;
  padding-right: 2rem;
}

.yellBG {
  background-color: #e7b33e1f;
}

#TSearchSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1145px;
  align-items: center;
  height: 50px;
}

.activitySectionHeader {
  font-family: "NexaBold";
  font-size: 22px;
}

#tableSearch {
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  width: 215px;
  border: 2px solid #4d6e8d;
}

#tableSearch::placeholder {
  color: black;
}

.yellowButton {
  background-color: #e7b33ebf;
  border-radius: 5px;
  border: none;
  height: 40px;
}
