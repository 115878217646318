.closed_drawer {
  width: 1300px;
  height: 1050px;
  position: absolute;
  z-index: 3;
  transform: translate(-1220px);
  transition: all 0.3s ease-in-out;
  border-radius: 0px 25px 25px 0px;
  background-color: #e7b33e;
}

/*  height: 800px;
*/
.open_drawer {
  transform: translate(0);
}

.closedDrawerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  width: 100%;
  height: 100%;
}

#openLeftDrawer {
  background-color: white;
  border: solid 2px #e7b33e;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0px 25px 25px 0px;
  padding-left: 40px;
  padding-bottom: 10px;
}

.openText {
  gap: 0.5rem;
  padding-top: 1rem;
}

.openText:hover {
  cursor: pointer;
}


.academy-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}


