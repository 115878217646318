.activityView {
  background-color: #191c26;
  width: 600px;
  height: 800px;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left:50%;
  transform: translate(0px);
}

@media only screen and (max-width: 1400px) {
  .expanded {
    width: calc(100.5vw - 160px);
    left:80px;
    right:80px;
  }
} 

@media only screen and (min-width: 1401px) {
  .expanded {
    width: calc(100vw - 160px);
    left:80px;
    right:80px;
  }
} 

.expanded {
  height: 800px;
  border-radius: 25px;
  z-index: 1;
  position:absolute;
  padding-right:20px;
  /*transform: translate(0px);*/
}
