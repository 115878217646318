#header {
  width: 100%;
  height: 100px;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
}

#activitySection {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.sideText {
  transform: rotate(270deg);
  font-family: "NexaBold";
  font-size: 16px;
}

#pdfView {
  width: 600px;
  height: 800px;
  border: solid 2px #191416;
  border-radius: 25px;
  overflow: hidden;
}

#rightDrawerSection {
  display: flex;
  flex-direction: column;
  height: 800px;
  width: 80px;
  justify-content: space-between;
}

.openText {
  display: flex;
  flex-direction: column;
  align-items: center;
}
