.accountHeader {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  #providerAccountForm {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;
  }
  
  .accountFormRowContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
  
  .accountInputContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .editButtonContainer {
    padding: 1rem 0rem 1rem 0rem;
  }

  .editButton {
    width: 170px;
    height: 40px;
    background-color: #e7b33e;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }

  .licensesContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 6rem;
    gap: 1rem;
  }

  .licensesLabels {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr;
  }

  .licenseInformationContainer {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr;
  }

  .licenseCode {
    color: blue;
  }

  .licenseCode:hover {
    scale: 1.1;
    cursor: pointer;
  }

  .adminLicensesLabels {
    display: grid;
    grid-template-columns: 3fr 1fr 2fr 2fr 1fr;
  }

  .emailSubscriptionsContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 6rem;
    gap: 1rem; 
  }

  .emailSubscriptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }