#outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.boldHeader {
  font-weight: bold;
  margin-bottom: -0.10rem;
  font-size: 16px;
  /* font-family: "Mulish"; */
}

.sectionHeader {
  font-family: "NexaBold"; 
  font-size: 22px;
  font-weight: 700;
}

.bioBox {
  display: flex;
}

.aboutRow {
  display: flex;
  justify-content: space-between;
}

.menu {
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;
}

.bioStyle {
  width: 400px;
  margin-bottom: 65px;
}

/* Hero Section */
#heroSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem 2rem;
}

#heroSectionHeader {
  font-family: "NexaBold";
  font-size: 22px;
  padding-bottom: 0.5rem;
}

#heroTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
}

.heroBodyText {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}

/* Play Section */
#playSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
  gap: 1rem;
}

#playSectionHeader {
  font-family: "NexaBold";
  font-size: 22px;
}

.playBodyText {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}

#playButton {
  width: 140px;
  height: 40px;
  color: white;
  background-color: #1c558a;
  border-radius: 5px;
  border: none;
  font-family: "NexaBold";
  font-size: 16px;
  font-weight: 700;
  margin-left: 1rem;
  margin-top: 1rem;
}

/* How To Section */
#howToSectionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem 2rem;
}

#howToSectionTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
}

.howToBodyText {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}

/* About Us Section */
#aboutUsSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  gap: 2rem;
}

.aboutUsBodyText {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}

/* Faq Section */
#faqSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
}

.faqBodyText {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}

/* Contact Section */
#contactSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 4rem 2rem;
}

.contactBodyText {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
}

/* News Section */
#newsSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
}
