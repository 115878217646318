#purchaseHeader {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0rem 2rem 0rem;
}

#purchaseHeaderText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#backButton {
  width: 100px;
  height: 30px;
  background-color: white;
  border: none;
}

#purchaseForm {
  font-family: Mulish;
  font-weight: 700;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.purchaseInputContainer {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.purchaseCountInputContainer {
  display: flex;
  gap: 1rem;
}

.countInput {
  width: 60px;
  height: 30px;
}

#submitButtonContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem 2rem 0rem;
  gap: 1rem;
}

#purchaseSubmitButton {
  font-family: Mulish;
  font-weight: bold;
  width: 100px;
  height: 40px;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}
.formButton {
  font-family: Mulish;
  font-weight: bold;
  width: 100px;
  height: 40px;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.purchaseParagraph{
  font-family: "Mulish";
  font-size: "17px";
  font-weight: "700";
}