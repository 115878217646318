#levelDrawer {
  background-color: #1c558a;
  border-radius: 25px 0px 0px 0px;
  color: white;
  height: 400px;
  /* width: 100%; */
  display: flex;
  z-index: 1;
}

.closedDrawerUpperRight {
  position: absolute;
  width: 80px;
  transform: translate(0px);
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.openDrawerUpperRight {
  transform: translate(-720px);
  width: 800px;
  z-index: 2;
}

#openLevelDrawer {
  background-color: white;
  border: solid 2px #1c558a;
  border-radius: 25px 0px 0px 0px;
  height: 395px;
  display: flex;
  overflow: hidden;
}
