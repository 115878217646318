#navContainer {
  width: 100vw;
  height: 118px;
  display: flex;
  align-items: center;
  background-color: #e7b33ebf;
}

#nav {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

#piggyBackLogo {
  width: 126px;
  height: 31px;
  /* These are the dimensions of the logo png file */
}

#navLinksContainer {
  width: 100%;
  height: 80px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#loginButton {
  width: 98px;
  height: 37px;
  font-family: "NexaBold";
  font-size: 20px;
  color: white;
  background-color: #1c558a;
  border: none;
  border-radius: 10px;
  align-self: self-end;
}

#navLinks {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  height: 20px;
  color: white;
}

.navLinkButton {
  background: none!important;
  border: none;
  font-family: "NexaBold";
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  /*input has OS specific font-family*/
  cursor: pointer;
}


