.videoContainer{
    margin-top: 40px;
    margin-bottom:40px;
    font-family: "NexaBold"; 
    font-size: 18px;
    font-weight: 400;
}

.sectionHeader {
    margin-top:40px;
    font-family: "NexaBold"; 
    font-size: 30px;
    font-weight: 700;
  }